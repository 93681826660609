export default {
  namespaced: true,

  state: {
    visibleMainMap: true, //显示主地图
    label_modal_visible: false,
    current_label_marker_id: null,
    current_label_data: {},
    tag_modal_visible: false,
    current_tag_id: null,
    current_tag_data: {},
    mapisStatus: {
      flag: false, //为false时候地图为全屏 ，为true 时 缩小
      type: "wrj", //类型
      num: null,
    },
    videoItem: 0,
    ycMapCollapse: false, //机巢小地图是否收起， false为展开， true为收起
  },

  mutations: {
    SET_MAP_IS_VIDEOITEM: (state, data) => {
      state.videoItem = data;
    },
    SET_MAP_IS_STATUSl: (state, data) => {
      state.mapisStatus = data;
      // state.mapisStatus.flag= data.flag
      // state.mapisStatus.type= data.type
    },
    SET_LABEL_MODAL_VISIBLE: (state, data) => {
      state.label_modal_visible = data;
    },
    SET_CURRENT_LABEL_MARKER_ID: (state, data) => {
      state.current_label_marker_id = data;
    },
    SET_CURRENT_LABEL_DATA: (state, data) => {
      state.current_label_data = data;
    },
    SET_TAG_MODAL_VISIBLE: (state, data) => {
      state.tag_modal_visible = data;
    },
    SET_CURRENT_TAG_ID: (state, data) => {
      state.current_tag_id = data;
    },
    SET_CURRENT_TAG_DATA: (state, data) => {
      state.current_tag_data = data;
    },
    SET_VISIBLE_MAIN_MAP: (state, data) => {
      state.visibleMainMap = data;
    },
    SET_YC_MAP_COLLAPSE: (state, data) => {
      state.ycMapCollapse = data;
    },
  },

  actions: {},
};
