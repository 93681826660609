import 'normalize.css/normalize.css'
import './styles/index.scss'

import './utils/lod'

import Vue from 'vue'
import App from './App.vue'

// 全局变量
import { message } from '@/utils/resetMessage'
import g_data from '@/utils/global.js'
Vue.prototype.g_data = g_data

Vue.config.productionTip = false

import mixin from './mixin/mixin'
Vue.mixin(mixin)

// element 滚动插件
import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)
// 时间插件
import moment from 'moment'
Vue.prototype.$moment = moment

import axios from 'axios'

import router from './router'
import './router/permission'

import store from './store'

import './plugins'
import './directives'

//引入阿里巴巴矢量图标库
import './assets/ali_font/iconfont.css'
import './assets/iconfont/iconfont.css'

// import echarts from "echarts";
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.prototype.$message = message

import recover from '@/plugins/recover'
Vue.prototype.$recover = recover
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)

window.$debug = localStorage.getItem('$debug') == 1 ? true : false //全局调试信息展示开关
window.$log = function (...args) {
  // console.log(args,'1111');
  if (window.$debug) {
    console.info(...args)
  }
}

window.$this = {} //用于储存测试用的实例

import Bus from '@/assets/ligature.js'
window.$Bus = Bus

// #region 不用了 2023年7月21日14:42:22
// 引入场景配置数据
// 这里在public/index.html使用script标签引入 ../public/config/Config.js 避免打包之后修改配置文件无效
// import sceneConfig from "../public/config/Config.js";
// readConfig();
// 读取配置文件
// async function readConfig () {
//   let index = sceneConfig['flag'];
//   if (index == 0) {
//     Vue.prototype.$configData = {};
//   } else {
//     let path = `/config/${sceneConfig.scene[index - 1]}.json`;
//     let r = await axios.get(path);
//     Vue.prototype.$configData = r.data;
//   }
// }
// #endregion

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')

// window.onerror = function(...args){
//   console.log("onerror", args);
//   return false;
// }
