import request from "../request";

export default class dtVideo {
  /**
   * 获取视频点位
   * @param {*} params
   * @returns
   */
  static getDeviceMediaUrl() {
    return request({
      url: "/hawksystemserver/task/MediaUrl/getMediaUrl", // 假设的视频列表 API 路径
      method: "get",
    });
  }
  /**
   * 获取视频url地址
   * @param {*} params
   * @returns
   */
  static getDeviceMediaUrlHls(params) {
    return request({
      url: "/hawksystemserver/task/MediaUrl/getMediaUrlHls", // 假设的视频列表 API 路径
      method: "get",
      params,
    });
  }
}
