import request from '../request'

/**
 * 空域
 */
export default class Airspace {
  /**
   * 列表
   * @param {*} params
   * @returns
   */
  static List (params) {
    return request.get('/hawksystemserver/airspace/web_selectLikeList', {
      params: params,
    })
  }

  /**
   * 限高区列表
   * @param {*} params
   * @returns
   */
  static GetLimitHeightAreas (params) {
    return request.get('/hawksystemserver/flight/getLimitHeightAreas', {
      params: params,
    })
  }
  /**
   * 禁飞区列表
   * @param {*} params
   * @returns
   */
  static GetNotFlyAreas (params) {
    return request.get('/hawksystemserver/flight/getNotFlyAreas', {
      params: params,
    })
  }
  /**
   * 电子围栏列表
   * @param {*} params
   * @returns
   */
  static GetAreaList (params) {
    return request.get('/hawksystemserver/flight/getAreaList', {
      params: params,
    })
  }
  /**
   * 电子围栏列表
   * @param {*} params
   * @returns
   */
  static GetCurrentAreaList (params) {
    return request.get('/hawksystemserver/flight/getCurrentAreaList', {
      params: params,
    })
  }

  /**
   * 查询航线名称数量
   * @param {*} params
   * @returns
   */
  static GetFlightLineCount (params) {
    return request.post('/hawksystemserver/flight/getFlightLineCount', params)
  }

  //空域类别
  static getTypeList (params) {
    return request.get(`hawksystemserver/airspacetype/getTypeList`)
  }
  // 空域类型
  static selectType (params) {
    return request.get(`/hawksystemserver/airspace/selectType`)
  }

  /**
   * 机构下拉列表
   * @param {*} params
   * @returns
   */
  static getAllOrganizationBack (params) {
    return request.get('/hawksystemserver/organization/getAllOrganizationBack', {
      params: params,
    })
  }
  /**
       * 航线列表
       * @param {*} params
       * @returns
       */
  static addAirSpaceWeb (params) {
    return request.post('/hawksystemserver/airspace/addAirSpaceWeb', params)
  }


  /**
       * 网格码
       * @param {*} params
       * @returns
       */
  static getAirSpaceGeoSotGrid (params) {
    return request.post('/hawksystemserver/flight/buildAirSpaceGeoSotGrids', params)
  }

  /**@Description :  ******************************************* 获取所有空域列表(不包括禁飞区、临时禁飞区、围栏、限高区)
   **@Date: 2024-03-27 09:33:48
  */
   static getFlightSpaceGetAreas (params) {
    return request.get('/hawksystemserver/flight/getAreas')
  }
  /**@Description :  ******************************************* 根据id获取航线
   **@Date: 2024-4-11 19:06:28
  */
   static getAirLineByIds (ids) {
    return request.get(`/hawksystemserver/flight/getFlightLine/${ids}`)
  }
   /**@Description :  ******************************************* 空域 线
    **@Date: 2024-04-16 14:39:23
  */
  static getAirspaceLineList(){
    return request.get(`/hawksystemserver/flight/getLineAreas`);
  }
  /**@Description :  ******************************************* 空域 点
   **@Date: 2024-04-16 16:00:11
  */
  static getAirspacePointList(){
    return request.get(`/hawksystemserver/flight/getPoints`);
  }
}
