import request from '../request'
import http from '../http'
import axios from 'axios'
/**
 * 航线
 */
export default class Airway {
  // 航线转换
  static GetAirwayInfo(id) {
    return http.get(`/hawksystemserver/airRoutes/transform2broker/${id}`)
  }
  /**
   * 航线标签
   * @param {*} params
   * @returns
   */
  static update(params) {
    return http.post('/hawksystemserver/flightLineLabel/update', params)
  }

  /**
   * 航线标签
   * @param {*} params
   * @returns
   */
  static insert(params) {
    return http.post('/hawksystemserver/flightLineLabel/insert', params)
  }

  /**
   * 下拉列表 机巢
   * @param {*} params
   * @returns
   */
  static getNestListForLine(params) {
    return request.get('/hawksystemserver/eagleNest/getNestListForLine', {
      params
    })
  }

  /**
   * 列表
   * @param {*} params
   * @returns
   */
  static getFlightLineLabel(params) {
    return request.get('/hawksystemserver/flightLineLabel/getFlightLineLabel', {
      params
    })
  }
  /**
    /hawksystemserver/flightLineLabel/getLabelById/{id}


    /**
     * 根据航线id获取航线标签数据接口
     * @param {*} params 
     * @returns 
     */
  static getLabelById(params) {
    return request.get(`/hawksystemserver/flightLineLabel/getLabelById/${params.id}`)
  }

  /**
   * 列表
   * @param {*} params
   * @returns
   */
  static getApprovedTask(params) {
    return request.get('/hawksystemserver/flightMission/task/getApprovedTask', {
      params
    })
  }
  /**
   * 列表
   * @param {*} params
   * @returns
   */
  static List(params) {
    return request.get('/hawksystemserver/flight/flightLineList', {
      params
    })
  }

  /**
   * 获取航线标签
   * @param {*} params
   * @returns
   */
  static labelList() {
    return request.get('/hawksystemserver/flightLineLabel/getFlightLineLabel')
  }

  /**
   * 获取起降场下拉列表
   * @param {*} params
   * @returns
   */
  static landList() {
    return request.get('/hawksystemserver/land/web_landList')
  }

  /**
   * 新增
   * @param {*} params
   * @returns
   */
  // static Add(params) {
  //     return request.post("/hawksystemserver/flight/insFlightLine", params)
  // }

  /**
   * 检查任务中的航线相交情况
   * @param {*} params
   * @returns
   */
  static checkFlightLineWithDLL(params) {
    return request.post('/hawksystemserver/flight/checkFlightLineWithDLL', params)
  }
  /**
   * 新增
   * @param {*} params
   * @returns
   */
  static Add(params) {
    return http.post('/hawksystemserver/flight/insFlightLine', params)
  }

  static ApplyFlightLineAuth(params) {
    return http.get('/hawksystemserver/flight/applyFlightLineAuth', {
      params
    })
  }

  static Delete(params) {
    return request.get('/hawksystemserver/flight/deleteFlightLine', { params })
  }

  /**
   * 编辑
   * @param {*} params
   * @returns
   */
  static Edit(params) {
    return request.post('/hawksystemserver/flight/insFlightLine', params)
  }

  /**
   * 下拉
   * @param {*} params
   * @returns
   */
  static Drd(params) {
    return request.get('/hawksystemserver/flight/getFlightLineList', {
      params
    })
  }

  /**
   * 自动航线规划//内网用
   * @param {*} params
   * @returns
   */
  //  static autoPlan(params){
  //     return axios2.get("/service/lbs/route/driving3", {
  //         params
  //     })
  // }
  // /32:128.12.138:21009

  /**
   * 改为安全状态
   * @param {*} params
   * @returns
   */
  static EditIssafe(params) {
    return request.post('/hawksystemserver/flight/editIssafe', params)
  }

  /**
   * 修改航线标签
   * @param {*} params
   * @returns
   */
  static updatatag(params) {
    return request.post('/hawksystemserver/flightLineLabel/updateLabelRelation', params)
  }
  /**
   * 机巢任务列表
   * @param {*} params
   * @returns
   */
  static getNestApprovedTask(params) {
    return request.get('/hawksystemserver/flightMission/task/getNestApprovedTask', {
      params
    })
  }

  /**
   * 改为安全状态
   * @param {*} params
   * @returns
   */
  static addTimingTask(params) {
    return request.post('/hawksystemserver/eagleNest/addTimingTask', params)
  }

  /**
   *
   * @param {*} params
   * @returns
   */
  static addNestAutoTask(params) {
    return request.post('/hawksystemserver/task/addNestAutoTask', params)
  }

  //迅蚁token
  static autoRouteToken = 'MTU3MjkjMTY1MzI2OTc0MEBhbGdvci10ZXN0Lnh5aXRlY2guY29tI2trZC9pTlF5MEgwemhiQnAxZVMreE5nL01UWT0='

  /**
   * 自动航线创建任务
   * @param {Object} params
   * @returns
   */
  // static planTaskCreateTask (params) {
  //   return axios.post(
  //     `${process.env.VUE_APP_AUTO_PLAN}/plan/task/create_task?token=${this.autoRouteToken}`,
  //     {
  //       token: this.autoRouteToken,
  //       range_points_list: [],
  //       alternate_route: {
  //         is_enabled: true,
  //         routes_num: 0,
  //       },
  //       obstacles_min_safety_distance: {
  //         horizontal_direction: 2,
  //         height: 2,
  //       },
  //       ...params,
  //     }
  //   );
  // }

  /**
   * 自动规划航线
   */
  static autoBuildFlightLine(params) {
    return request.post('/hawksystemserver/flight/buildRoute', params)
  }
  /**@Description :  ******************************************* 自动规划航线新接口
   **@Date: 2024-07-16 11:01:42
  */
  static autoBuildFlightLineNew(isFollowAirLine, params) {
    return request.post(`/hawksystemserver/flight/buildRoute/${isFollowAirLine}`, params)
  }
  /**
   * 自动航线确认任务
   * @param {Object} params
   * @returns
   */
  static planTaskControl(params) {
    return axios.post(`${process.env.VUE_APP_AUTO_PLAN}/plan/task/control?token=${this.autoRouteToken}`, {
      id: params.id,
      cmd: 'start',
      device_id: 100
    })
  }

  /**
   * 搜索航线
   * @param {Object} params
   */
  static planTaskResultSearch(params) {
    return axios.get(`${process.env.VUE_APP_AUTO_PLAN}/plan/task/result/search?token=${this.autoRouteToken}&task_id=${params.task_id}`)
  }

  /**
   *@description:
   *@author: name
   *@date: 2023-10-07 14:38:01
   *@params1:
   *@return1:
   */
  static check(params) {
    return http.post('/hawksystemserver/flight/checkFlightLineWithDLL', params)
  }
  /**@Description :
   *@Date : 2024-01-22 15:03:15
   *@Param :
   *@Return :
   */
  static updateFlightLine(params) {
    return http.post('/hawksystemserver/flight/updateFlightLine', params)
  }
  /**@Description :  ******************************************* 航线生成网格码 
   **@Date: 2024-04-07 10:10:27
  */
 static createRouteGrids(level, data){
  return http.post(`/hawksystemserver/flight/createRouteGrids/${level}`, data);
 }
 /**@Description :  ******************************************* 根据id获取网格码数据
  **@Date: 2024-04-08 14:39:20
 */
 static getAirSpaceGeoSotGridsById(id, level){
    return http.get(`/hawksystemserver/flight/getAirSpaceGeoSotGridsById/${id}/${level||15}`);
 }
  /**@Description :  ******************************************* 根据航线id查询 flightCursorJson
   **@Date: 2024-06-26 13:45:35
  */
  static getFlightCursorJsonByLineId(id) {
    return http.get(`/hawksystemserver/task/getFlightLineById/${id}`);
  }
  /**@Description :  ******************************************* 分页查询航线列表
   **@Date: 2024-06-26 13:58:16
  */
  static getFlightLineListPage(params) {
    return request.get('/hawksystemserver/flight/getFlightLineListPage', { params })
  }
  /**@Description :  ******************************************* 上传kml航线
   **@Date: 2024-07-15 10:48:28
  */
  static uploadKml(data) { 
    return request.post('/hawksystemserver/airspace/parseKml', data)
  }
}
