//飞控中心相关
export default {
  namespaced: true,

  state: {
    selectMount: null, //选择人挂载
    nestCollapse: false, //鹰视左侧收起
    taskId: null, //选择的任务id
    YJtaskId: null, //选择的任务id（鹰击）
    obstacle: null, //避障信息 { deviceHardId: string(设备id), distances: array<int>（每10度一个与障碍物的距离厘米）; enable: boolean（是否在避障中）; obsDistance:int(避障警告距离米)}
    yc_video_status: "专网", //机巢视频使用公网链接还是专网链接
    stream: "", //当前视频流类型
  },

  mutations: {
    SET_STREAM(state, data) {
      state.stream = data;
    },
    SET_SELECT_MOUNT(state, data) {
      state.selectMount = data;
    },
    SET_NEST_COLLAPSE(state, data) {
      state.nestCollapse = data;
    },
    SET_TASK_ID(state, data) {
      state.taskId = data;
    },
    SET_TASK_ID_YJ(state, data) {
      state.YJtaskId = data;
    },
    SET_OBSTACLE(state, data) {
      state.obstacle = data;
    },
    SET_YC_VIDEO_STATUS(state, data) {
      state.yc_video_status = data;
    },
  },

  actions: {},
};
