import request, { request_fckernel } from "../request";

/**
 * 飞控中心 api
 */
export default class FCKernel {
  /**
   * 新增调试记录
   * @param {*} data
   * @returns
   */
  static inserWebLogs(data) {
    return request.post("/hawklogs/webLogs/insertWebLogs", data);
  }
  /**
   * 无人机列表
   * @param {*} params
   * @returns
   */
  static MountedListUAV(params) {
    return request.get(`/hawksystemserver/device/organTree/`, {
      params,
    });
  }
  //     stationType: 1  //1px4 0apm
  /**
   * 无人机列表
   * @param {*} params
   * @returns
   */
  static ListUAV(params = {}) {
    return request.get(`/hawksystemserver/device/organTree/`, {
      params: {
        stationType: 1, //1px4 0apm
        search: params.search,
      },
    });
  }
  /**
   * 天气
   * @param {*} params
   * @returns
   */
  static getNestWeather(params) {
    return request.get(`/hawksystemserver/eagleNest/getNestWeather`, {
      params,
    });
  }
  /**
   * 航线下拉
   * @param {*} params
   * @returns
   */
  static DrdAirway(params) {
    return request.get(`/hawksystemserver/flight/getFlightLineList`, {
      params,
    });
  }

  /**
   * 删除
   * @param {*} params
   * @returns
   */
  static deleteTaskById(params) {
    return request.get(
      `/hawksystemserver/eagleNest/deleteTaskById/${params.id}`
    );
  }

  /**
   * 航线格式转换
   * @param {*} params
   * @returns
   */
  static TransAirway(params) {
    return request.get(`/hawksystemserver/flight/getBrokerFlightLineById`, {
      params,
    });
  }

  /**
   * 根据硬件 id 查询无人机最新数据
   * @param {*} params
   * @returns
   */
  static GetLastUavData(params) {
    return request.get("/hawksystemserver/task/getMountInfoByDeviceHardId", {
      params: params,
    });
  }

  /**
   * 根据 id 查询无人机视频信息
   * @param {*} id
   * 0 webrtc
   * 1 flv
   * 2 rtsp
   * 3 m3u8
   * @returns
   */
  static GetVideoByDeviceHardId(data) {
    return request.get(
      `/hawksystemserver/device/getIndexDeviceMountStreamAddress/${data.id}/0?onLine=${data.onLine}`
    );
  }

  /**
   * 根据设备id获取视频流集合
   * @param {*} data
   * @returns
   */
  static GetDeviceStreamAddressByDeviceId(data) {
    return request.get(
      `/hawksystemserver/device/getDeviceStreamAddressByDeviceId/${data.deviceHardId}`
    );
  }

  /**
   * 判断用户是否有无人机接管权限
   * @param {*} params
   * @returns
   */
  static getTakeOverInfo(params) {
    return request.get("/hawksystemserver/device/getTakeOverInfo", {
      params: params,
    });
  }
  /**
   * 判断用户是否有无人机控制权限
   * @param {*} params
   * @returns
   */
  static checkUseOperate(params) {
    return request.get("/hawksystemserver/device/checkUseOperate", {
      params: params,
    });
  }

  /**
   * 退出无人机接管
   * @param {*} params
   * @returns
   */
  static exitTaskOver(params) {
    return request.get("/hawksystemserver/device/exitTakeOver", {
      params: params,
    });
  }

  /**
   * 用户申请接管无人机
   * @param {*} params
   * @returns
   */
  static checkUavControl(params) {
    return request.get("/hawksystemserver/device/checkUavControl", {
      params: params,
    });
  }

  /**
   * 修改无人机的接管状态
   * @param {*} params
   * @returns
   */
  static updateUavControlStatus(params) {
    return request.get("/hawksystemserver/device/updateUavControlStatus", {
      params: params,
    });
  }

  /**
   * 查询接管列表
   * @param {*} params
   * @returns
   */
  static getAllTakeOverList(params) {
    return request.get("/hawksystemserver/device/getAllTakeOverList", {
      params: params,
    });
  }

  /**
   *  任务库 -任务记录
   * @param {*} params
   * @returns
   */
  static addTaskAndUserRelation(data) {
    return request.post("/hawksystemserver/task/addTaskAndUserRelation", data);
  }

  /**
   * 查询寻当前用户关联的任务
   * @param {*} params
   * @returns
   */
  static getTaskAndUserRelation(data) {
    return request.get(
      `/hawksystemserver/task/getTaskAndUserRelation/${data.sourceType}`,
      {
        params: {
          deviceHardId: data.deviceHardId,
        },
      }
    );
  }
  /**
   * 修改用户和任务关联的方式
   * @param {*} params
   * @returns
   */
  static updateTaskAndUserRelation(data) {
    return request.put(
      "/hawksystemserver/task/updateTaskAndUserRelation",
      data
    );
  }

  /**
   * C查询机巢是否有任务冲突
   * @param {*} params
   * @returns
   */
  static getNestTaskByTime(params) {
    return request.get(
      `/hawksystemserver/eagleNest/getNestTaskByTime/${params.nestId}`
    );
  }

  //视图列表
  static photoAndvideo(visitQueryForm) {
    return request.post("/upload/visit/taskList", visitQueryForm);
  }
  //视图删除
  static deletephotoAndvideo(params) {
    return request.delete("/upload/visit/delList", {
      params: params,
    });
  }
  //机载视图列表
  static devicephoto(params) {
    // return request.get("http://32.128.5.10:20251/log-file-record/list", //52
    return request.get(
      "http://32.128.6.70:20251/log-file-record/list", //48
      {
        params,
      }
    );
  }
  //机载视图删除
  static deletelphoto(params) {
    //  return request.get(`http://32.128.5.10:20251/log-file-record/delete?${params}`);//52
    return request.get(
      `http://32.128.6.70:20251/log-file-record/delete?${params}`
    ); //48
  }
  //视图上传
  static Upload(data) {
    return request({
      url: `/upload/visit/insertByFile`,
      method: "post",
      data,
    });
  }
  //视图上传
  static Uploads(data) {
    return request({
      url: `/upload/visit/insertByFiles`,
      method: "post",
      data,
    });
  }
  //文件转url
  static fileUrl(url) {
    return request({
      url: url,
      method: "get",
      responseType: "blob",
    });
  }
  /**
   * 运行监测日志
   * @param {*} data
   * @returns
   */
  static saveDeviceRunMonitorLog(data) {
    return request.post(
      "/hawksystemserver/UavLog/saveDeviceRunMonitorLog",
      data
    );
  }
  /**
   * 新增设备异常记录
   * @param {*} data
   * @returns
   */
  static exceptionAdd(data) {
    return request.post("/hawksystemserver/device_exception_log/add", data);
  }
  /**
   * 设备异常记录列表
   * @param {*} params
   * @returns
   */
  static findAll(params) {
    return request.get("/hawksystemserver/device_exception_log/findAll", {
      params,
    });
  }
  /**
   * 通过任务id获取无人机信息
   * @param {*} params
   * @returns
   */
  static getDeviceInfoByTaskId(params) {
    return request.get("/hawksystemserver/device/getDeviceInfoByTaskId", {
      params,
    });
  }
  /**
   * 获取任务详情
   * @param {*} params
   * @returns
   */
  static getTaskDetail(data) {
    return request.post("/hawksystemserver/task/logistics/detail", data);
  }
}
