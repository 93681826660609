import request from '../request'

export default class User {
  /**
   * 应急打卡
   * @param {*} 
   * @return
   */
   static addUrgentCardLog(params) {
    return request.post(`/hawksystemserver/person-management/addUrgentCardLog`,params)
  }
 /**
   * 查询更新公告
   * @param {*} id
   * @return
   */
  static getUpdateUser(id) {
    return request.get(`/hawksystemserver/user/${id}/hasBeenNoticed`)
  }
  /**
   * 修改更新公告
   * @param {*} id
   * @return
   */
   static changeUpdate(id) {
    return request.post(`/hawksystemserver/user/${id}/beenNoticed`)
  }
  /**
   * 通讯联络
   * @param {*} params
   * @return
   */
   static getUser(params) {
    return request.get('/hawksystemserver/user/selectLikeList', {
      params,
    })
  }
  /**
   * 登录
   * @param {*} data
   * @returns
   */
  static Login(data) {
    return request.post('/hawksystemserver/client/login', data)
  }

  /**
   * 当天值班
   * @param {*} params
   * @returns
   */
  static GetDuty(params) {
    return request.get('/hawksystemserver/roster/getCurrentDayDuty', {
      params,
    })
  }

  /**
   * 值班列表
   * @param {*} param
   * @returns
   */
  static ListDuty(params) {
    return request.get('/hawksystemserver/home/Duty_roster_test_list', {
      params,
    })
  }

  /**
   * 飞手下拉
   * @param {*} params
   * @returns
   */
  static DrdPilot(params) {
    return request.get('/hawksystemserver/task/getFlyingHandList', {
      params,
    })
  }

  /**
   * 获取用户当前视角
   * @param {*} params
   * @returns
   */
  static GetOrigin(params) {
    return request.get('/hawksystemserver/user/getUserOrigin', {
      params,
    })
  }

  /**
   * 修改用户当前视角
   * @param {*} params
   * @returns
   */
  static UpdateOrigin(data) {
    return request.post('/hawksystemserver/user/updateUserOrigin', data )
  }

  /**
   * 打卡提醒列表
   * @param {*} params
   * @returns
   */
  static CheckList(params) {
    return request.get(
      'hawksystemserver/person-management/getOneNotificationsByReceiveId',
      {
        params,
      }
    )
  }
  /**
   * 打卡提醒列表确认查看
   * @param {*} params
   * @returns
   */
  static CheckListConfirm(data) {
    return request.post(
      'hawksystemserver/person-management/updateNoticeById',
      data
    )
  }

  /**
   * 资源报备
   * @param {*} params
   * @returns
   */
  static ResourceReport(data) {
    return request.post('hawksystemserver/resource-report/add', data)
  }

  /**
   * 更多列表
   * @param {*} params
   * @returns
   */
   static ManyBtnList() {
    return request.get('hawksystemserver/home/getSubscriptionMessageListMore')
  }

  /**
   * 空域权限
   * @param {*} params
   * @returns
   */
   static airspaceqx(params) {
    return request.get('/hawksystemserver/flight/applyFlightLineAuthList',{params})
  }

  /**
   * 航线详情
   * @param {*} id
   * @returns
   */
   static routesListqq(id) {
    return request.get(`/hawksystemserver/flight/flightLineInfo?id=${id}`)
  }

   /**
   * 空域审批
   * @param {*} id
   * @returns
   */
    static approval(id,status) {
      return request.get(`/hawksystemserver/flight/updApplyFlightLineAuth/${id}/${status}`)
    }

  /**
   * 警情中心 重新提交
   * @param {*} params
   * @returns
   */
   static Industryagain(data) {
    return request.post('hawksystemserver/jingqing/policeDispatch', data)
  }

  /**
   * 任务详情
   * @param {*} id
   * @returns
   */
   static detailslist(id) {
    return request.get(`hawksystemserver/task/getApproveTaskObjById/${id}`)
  }


  // /**
  //  * 订阅功能
  //  * @param {*} params
  //  * @returns
  //  */
  //  static subscribe(data) {
  //   return request.post('/hawksystemserver/home/subscriptionMessage', data)
  // }


  /**
   * 数字提示
   * @param {*} params
   * @returns
   */
   static Digitaltip() {
    return request.get(`hawksystemserver/home/getAllTypeStatistics`)
  }

  /**
   * 调度审批列表
   * @param {*} params
   * @returns
   */
     static schedulqx() {
      return request.get(`hawksystemserver/home/getDispatchApproveList`)
    }

  /**
   * 调度审批审批功能
   * @param {*} params
   * @returns
   */
     static approvalqx(data) {
      return request.post(`hawksystemserver/home/examinationApproval`,data)
    }

  /**
   * 订阅对√
   * @param {*} params
   * @returns
   */
       static issubscribe(data) {
        return request.post(`hawksystemserver/home/cancelSubscriptionMessage`,data)
      }


  /**
   * 任务审批搜索
   * @param {*} params
   * @returns
   */
   static taskSearch(params) {
    return request.get(`hawksystemserver/task/getApproveTaskList`,{params})
  }


  /**
   * 空域权限搜索
   * @param {*} params
   * @returns
   */
   static airspaceSearch(params) {
    return request.get(`hawksystemserver/flight/applyFlightLineAuthList`,{params})
  }


  /**
   * 调度审批搜索
   * @param {*} params
   * @returns
   */
   static Schedulingsearch(params) {
    return request.get(`hawksystemserver/home/getDispatchApproveList`,{params})
  }
  
}
