//飞控中心相关
export default {
  namespaced: true,

  state: {
    startGrade: false,    //开启战时状态
  },

  mutations: {
    SET_START_GRADE(state, data) {
      state.startGrade = data;
    },
  },

  actions: {},
};
