export default {
  namespaced: true,

  state: {
    isShow: true,
    toolbarIsShow: true,
    isShowFactorIcons: true,
    taskType: "",
    flag:false,// 全要素是否折叠
    mapFlag:false,// 地圖
    waterFlag:false,//水质检测
    waterbox:false,//水质检测表
    videoFlag:false,//飞控视频展示
    move_data:{},//飞控中心 指屏幕点移动
    move_ys_data:{},// 鹰视
    is3d: false,
    wsState: null,//飞行监控页面ws连接状态  0：表示连接尚未建立 1：表示连接已建立 2：表示连接正在进行关闭握手 3：表示连接已经关闭或者根本没有建立
    whxfWsState: null,// 芜湖消防ws连接状态  0：表示连接尚未建立 1：表示连接已建立 2：表示连接正在进行关闭握手 3：表示连接已经关闭或者根本没有建立
  }, 

  mutations: {
    SET_3D_STATUS:(state,data)=>{
      state.is3d = data
    },
    SET_MOVE_YS_DATA:(state, data)=>{
      state.move_ys_data = data
    },
    SET_MOVE_DATA:(state, data)=>{
      state.move_data = data
    },
    SET_VIDEO:(state, data)=>{
      state.videoFlag = data
    },
    IS_SHOW: (state, data) => {
      state.isShow = data
    },
    TOOLBAR_IS_SHOW: (state, data) => {
      state.toolbarIsShow = data
   
    },
    SET_TASKTYPE: (state, data) => {
      state.taskType = data
    },
    IS_SHOW_FACTOR_ICONS: (state, data) => {
      console.log("IS_SHOW_FACTOR_ICONS");
      state.isShowFactorIcons = data
    },
    
    SET_FlAG:(state, data)=>{
      state.flag=data
    },
    SET_MAPFLAG:(state, data)=>{
      state.mapFlag=data
    },
    SET_WATERFlag:(state, data)=>{
      state.waterFlag=data
    },
    SET_WATERBOX:(state, data)=>{
      state.waterbox=data
    },
    SET_WS_STATE:(state, data)=>{
      state.wsState=data
    },
    SET_WS_WHXF_STATE: (state, data) => {
      state.whxfWsState=data
    },
    
  },

  actions: {},
}
