import { request_nest } from "../request";
import { request_fckerne_nest } from "../request";
import request from "../request";

export default class Nest {
  //机巢列表
  static List (params) {
    return request_nest.get("master/treeSettings", {
      params,
    });
  }
  // 新机巢列表
  static treeSettings (params) {
    return request_nest.get("/hawksystemserver/nest/treeSettings", {
      params,
    });
  }

  static GetCurrentUsernameTasks2 (params) {
    return request.get("/hawksystemserver/task/getCurrentUsernameTasks2", {
      params,
    });
  }

  static FlightLineInfoByTaskId (params) {
    return request.get(
      `/hawksystemserver/flight/flightLineInfoByTaskId/${params.id}`
    );
  }

  static AddAirline (data) {
    return request_nest.post("/airline/addAirline", data);
  }

  static GetHistoricalTrajectory (data) {
    return request_nest.get(`/airline/getHistoricalTrajectory/${data}`);
  }

  /**
   * 添加航线
   * @param {*} data
   * @returns
   */
  static AddAirway (data) {
    return request_nest.post(`/airline2broker/addAirline`, data);
  }

  /**
   * 航线列表
   * @param { Object } params
   * @returns
   */
  static ListAirway (params) {
    return request_nest.get(`/airline2broker/select-airline-list`, {
      params,
    });
  }

  /**
   * 航线下拉
   * @param {*} params
   * @returns
   */
  static DrdAirway (params) {
    return request_nest.get(`/airline2broker/airline-list`, {
      params,
    });
  }

  /**
   * 航线删除
   * @param {*} id
   * @returns
   */
  static DeleteAirway (id) {
    return request_nest.post(`/airline2broker/delAirline?id=${id}`);
  }

  /**
   * 航线详情
   * @param {*} id
   * @returns
   */
  static GetAirway (id) {
    return request_nest.get(`/airline2broker/getAirlineInfo/${id}`);
  }

  static getNestList (params) {
    return request_fckerne_nest.get(`/hawksystemserver/eagleNest/getNestList`, {
      params,
    });
  }

  static getStepList (params) {
    // http://32.128.6.52:20251/api/apron/getProcessList
    return request_nest.get(
      // `http://32.128.6.52:20251/api/apron/getProcessList`,
      `https://ualins.uatair.com:20251/api/apron/getProcessList`,
      {
        params,
      }
    );
    // return request_nest.get(`http://apron.mmcuav.cn:20251/api/apron/getProcessList`, {
    //   params
    // })
  }

  static getTimingTaskListByNestId (data) {
    return request_fckerne_nest.get(
      `/hawksystemserver/eagleNest/getTimingTaskListByNestId/${data.nestId}/${data.taskType}`
    );
  }
}
