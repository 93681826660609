import request from "../request";
export default class taskOrder {
  /**
   * 任务订单顶部状态统计
   */
  static statusCount() {
    return request.get(`/hawksystemserver/task/logistics/statusCount`);
  }
  /**@Description :  ******************************************* 首页-物流任务列表
   **@Date: 2024-03-15 17:42:41
   */
  static taskListForHomePage() {
    return request.get(`/hawksystemserver/task/logistics/taskListForHomePage`);
  }
  static taskListPage(data) {
    return request.post(`/hawksystemserver/task/logistics/taskListPage`, data);
  }
  /**@Description :  ******************************************* 起降场 下拉列表
   **@Date: 2024-03-16 13:49:57
   */
  static web_landList() {
    return request.get(`/hawksystemserver/land/web_landList`);
  }
  /**@Description :  ******************************************* 新建订单接口
   **@Date: 2024-07-12 13:49:57
   */
  static createOrder(data) {
    return request.post(`/hawksystemserver/task/logistics/create`, data);
  }
  /**@Description :  ******************************************* 航线下拉列表
   **@Date: 2024-03-16 14:01:14
   */
  static getFlightLineList(params) {
    // return request.get(`/hawksystemserver/flight/getFlightLineList`, {params})
    return request.get(`/hawksystemserver/flight/uupaotui/downlList`, {
      params,
    });
  }
  /**@Description :  ******************************************* 获取无人机列表
   **@Date: 2024-03-16 14:54:59
   */
  static getDeviceListForLogistics() {
    return request.get(`/hawksystemserver/device/getDeviceListForLogistics`);
  }
  /**@Description :  ******************************************* 获取订单详情
   **@Date: 2024-03-16 15:17:44
   */
  static getOrderDetails(data) {
    return request.post(`/hawksystemserver/task/logistics/detail`, data);
  }
  /**@Description :  ******************************************* 任务审核
   **@Date: 2024-03-16 16:32:03
   */
  static taskAudit(data) {
    return request.post(`/hawksystemserver/task/logistics/audit`, data);
  }
  /**@Description :  ******************************************* 删除订单任务
   **@Date: 2024-05-30 14:47:22
   */
  static delDevByIdsSubmit(deliveryIds) {
    return request.post(`/hawksystemserver/task/logistics/del/${deliveryIds}`);
  }
}
