<template>
  <!-- 路由显示页面 -->
  <router-view />
</template>
<script>
// ewqe
// sda
export default {
  data() {
    return {
      visible: true,
    };
  },
  // 0120
  // sdada
  mounted() {
    //d
    // this.resetLog();
    // console.log = function(){}
  },
  methods: {
    resetLog() {
      console.log = (function (log) {
        return process.env.NODE_ENV == 'production' ? function () {} : log;
      })(console.log);
    },
  },
};
</script>

<style lang="scss" scoped>
.app {
  // min-width: 2560px;
  // min-height: 1080px;
  // width: 2560px;
  // height: 1080px;
  // overflow: auto;
}
</style>
