import request from "../request";
import { getRequest_opensky } from '../http';

export default class Tower {
  /**
   * 航线列表
   * @param {*} params
   * @returns
   */
  static ListAirway(params) {
    return request.get(`/hawksystemserver/task2uav/getTaskFlightLineSelect`, {
      params,
    });
  }

  /**
   * 所有航线列表
   * @param {*} params
   * @returns
   */
  static GetAllAirway(params) {
    return request.get(`/hawksystemserver/task2uav/getTaskFlightLineAll`, {
      params,
    });
  }

  /**
   * 所有航线列表
   * @param {*} params
   * @returns
   */
  static ListStation(params) {
    return request.get(`/hawksystemserver/task2uav/baseList`, {
      params,
    });
  }

  /**
   * 无人机预警
   * @param {*} params
   * @returns
   */
  static DeviceWarning(params) {
    return request.get("/hawksystemserver/task2uav/deviceWarningList", {
      params,
    });
  }

  /**
   * 机巢预警
   * @param {*} params
   * @returns
   */
  static NestWarning(params) {
    return request.get("/hawksystemserver/task2uav/nestWarningList", {
      params,
    });
  }

  /**
   * 基站预警
   * @param {*} params
   * @returns
   */
  static BaseWarning(params) {
    return request.get("/hawksystemserver/task2uav/baseWarningList", {
      params,
    });
  }

  /**
   * 警情列表
   * @param {*} params
   * @returns
   */
  static JingQingList(params) {
    return request.get("/hawksystemserver/jingqing/getAllJingQingList3", {
      params,
    });
  }

  static FlightLog(params) {
    return request.get(`/hawksystemserver/flightLog/${params.id}`);
  }
  /**
   * 获取opensky实时数据
   * @param {*} params
   * @returns
   */
  static getOpenSkyData() {
    return getRequest_opensky('/states/all')
  }
  /**
   * 拆分获取opensky实时数据
   * @param {*} params
   * @returns
   */
  static getOpenSkyData1() {
    return getRequest_opensky('/states/all?lamin=-65&lamax=65&lomin=0&lomax=90')
  }
  /**
   * 拆分获取opensky实时数据
   * @param {*} params
   * @returns
   */
  static getOpenSkyData2() {
    return getRequest_opensky('/states/all?lamin=-65&lamax=65&lomin=90&lomax=180')
  }
  /**
   * 拆分获取opensky实时数据
   * @param {*} params
   * @returns
   */
  static getOpenSkyData3() {
    return getRequest_opensky('/states/all?lamin=-65&lamax=65&lomin=-180&lomax=-90')
  }
  /**
   * 拆分获取opensky实时数据
   * @param {*} params
   * @returns
   */
  static getOpenSkyData4() {
    return getRequest_opensky('/states/all?lamin=-65&lamax=65&lomin=-90&lomax=0')
  }
}
