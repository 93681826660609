import Vue from "vue";
import VueRouter from "vue-router";
let sphereCom = () => import(`@/pages/eagleStrike/qjt.vue`);
Vue.use(VueRouter);

let envType = process.env.VUE_APP_ENV_TYPE;
let loginCom = "";
switch (envType) {
  case "公网":
    loginCom = () => import(`@/pages/login_public`);
    break;

  default:
    loginCom = () => import(`@/pages/login`);
}

export const createRouter = () =>
  new VueRouter({
    // mode: 'history', // require service support
    mode: "hash",
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: [
      {
        path: "/login",
        component: loginCom,
      },
      {
        path: "/videos",
        component: () => import("@/components/videosPage"),
      },
      {
        path: "/screenDisplay",
        component: () => import("@/pages/boss/index.vue"),
      },
      {
        path: "",
        component: () => import("@/components/layout"),
        redirect: "/home",
        children: [
          {
            path: "/eagleStrike",
            component: () => import("@/pages/eagleStrike"),
          },
          {
            path: "/eagleStrike_new",
            component: () => import("@/pages/eagleStrike/indexNew.vue"),
          },
          {
            path: "/home",
            component: () => import("@/pages/home/index.vue"),
          },
          {
            path: "/command",
            component: () => import("@/pages/command"),
          },
          {
            path: "/observe",
            redirect: "/observe/home",
          },
          {
            path: "/observe/home",
            component: () => import("@/pages/observe/home"),
          },
          {
            path: "/observe/home_v1",
            component: () => import("@/pages/observe/home/index_new.vue"),
          },
          // *************************************************************** uu跑腿路由开始
          {
            path: "/observe_uu",
            redirect: "/observe_uu/home",
          },
          {
            path: "/observe_uu/home",
            component: () => import("@/pages/observe_uu/home"),
          },
          {
            path: "/observe_uu/home_v1",
            component: () => import("@/pages/observe_uu/home/index_new.vue"),
          },
          {
            path: "/fckernel_uu",
            component: () => import("@/pages/observe_uu/fckernel"),
          },
          {
            path: "/fckernelVideos_uu",
            component: () => import("@/pages/observe_uu/fckernelVideos"),
          },
          {
            path: "/taskOrder_uu",
            // component: () => import("@/pages/observe_uu/fckernel/task_order"),
            component: () => import("@/pages/observe_uu/taskOrder"),
          },
          // *************************************************************** uu跑腿路由结束
          // *************************************************************** 芜湖消防路由开始
          {
            path: "/observe_whxf",
            redirect: "/observe_whxf/home",
          },
          {
            path: "/observe_whxf/home",
            component: () => import("@/pages/observe_whxf/home"),
          },
          {
            path: "/observe_whxf/home_v1",
            component: () => import("@/pages/observe_whxf/home/index_new.vue"),
          },
          {
            path: "/fckernel_whxf",
            component: () => import("@/pages/observe_whxf/fckernel"),
          },
          {
            path: "/fckernelVideos_whxf",
            component: () => import("@/pages/observe_whxf/fckernelVideos"),
          },
          {
            path: "/taskOrder_whxf",
            component: () => import("@/pages/observe_whxf/taskOrder"),
          },
          {
            path: "/uav_whxf",
            component: () => import("@/pages/observe_whxf/uavWHXF"),
          },
          {
            path: "/uav_whxf_alert",
            component: () => import("@/pages/observe_whxf/uavWHXF/components/noticeAlert"),
          },
          // *************************************************************** 芜湖消防支队路由结束
          {
            path: "/accident",
            component: () => import("@/pages/accident"),
          },
          {
            path: "/fckernel",
            component: () => import("@/pages/observe/fckernel"),
          },
          {
            path: "/fckernelVideos",
            component: () => import("@/pages/observe/fckernelVideos"),
          },
          {
            path: "/server_details",
            component: () => import("@/pages/server_details/index.vue"),
          },
          // 添加单独网格码页面
          {
            path: "/grids",
            component: () => import("@/pages/grids/index.vue"),
          },
          // {
          //   path: "/uav_whxf_alert",
          //   component: () => import("@/pages/observe_whxf/uavWHXF/components/noticeAlert"),
          // },
        ],
      },
      // 全景图
      {
        path: "/sphere",
        component: sphereCom,
      },
    ],
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
