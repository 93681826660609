import Lockr from 'lockr'
import axios from 'axios'
import qs from "qs"
import { message } from "@/components/message"

import store from "../store"
import router from "../router"

const {
  VUE_APP_BASE_URL,
  VUE_APP_NEST_URL,
  VUE_APP_FCKERNEL_URL,
  VUE_APP_NESTFK_URL
} = process.env


const isDev = process.env.NODE_ENV === "development" ? true : false
const request = axios.create({
  baseURL: VUE_APP_BASE_URL,
  timeout: 100000
})

const interceptors_request = [config => {
  // console.log(config, 'configconfigconfigconfigconfigconfig');

  if (config.qs == true) {
    config.url = config.url + '?' + qs.stringify(config.params, { indices: false })
    config.params = null
  }

  if (Lockr.get("FLYINGSESSIONID")) {
    config.headers.FLYINGSESSIONID = Lockr.get("FLYINGSESSIONID")
  }

  if (Lockr.get("IDENTITY")) {
    config.headers["mmc-identity"] = Lockr.get("IDENTITY")
  }

  // config.headers["Cross-Origin-Opener-Policy"] = "same-origin";
  // config.headers["Cross-Origin-Embedder-Policy"] = "require-corp";


  return config
}, error => Promise.reject(error)]

request.interceptors.request.use(...interceptors_request)

const interceptors_response = [response => {
  // console.log(response, 'responseeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee');
  let { status, message: msg, data } = response.data
  if (response.status == 469) {
    message({
      message: '操作频繁!',
      duration: 2000,
      type: "error"
    })
  }
  if (status == 1 || !status) {
    if (!data) {
      return response.data
    } else {
      return data
    }
  }

  else if (status == 621) {
    // 用户登录已过期
    store.dispatch("user/Logout")
    router.go(0)
  } else {
    message({
      message: msg,
      duration: 2000,
      type: "error"
    })
  }

  return Promise.reject(response.data)

}, error => Promise.reject(error
  // console.log(123)
  // message({
  //     message: '接口地址访问失败！',
  //     duration: 2000,
  //     type: "error"
  // })
)]
request.interceptors.response.use(...interceptors_response)

export const request_nest = axios.create({
  baseURL: VUE_APP_NEST_URL,
  timeout: 100000
})
request_nest.interceptors.request.use(...interceptors_request)
request_nest.interceptors.response.use(...interceptors_response)

export const request_fckernel = axios.create({
  baseURL: VUE_APP_FCKERNEL_URL,
  timeout: 100000
})
request_fckernel.interceptors.request.use(...interceptors_request)
request_fckernel.interceptors.response.use(...interceptors_response)
export const request_fckerne_nest = axios.create({
  baseURL: VUE_APP_NESTFK_URL,
  timeout: 100000
})
request_fckerne_nest.interceptors.request.use(...interceptors_request)
request_fckerne_nest.interceptors.response.use(...interceptors_response)
// 获取本地数据,需exe功能配合
export const request_localhost = axios.create({
  baseURL: 'localhost',
  timeout: 100000
})
// 服务器详情
export const request_server = axios.create({
  baseURL: process.env.VUE_APP_SWTX_ENV === 'production' ? 'http://32.128.6.52:8084' : '',
  timeout: 100000
})
request_server.interceptors.request.use(...interceptors_request)
request_server.interceptors.response.use(...interceptors_response)
// 清流信息
export const request_qingliu = axios.create({
  baseURL: 'http://32.128.6.46:5000',
  timeout: 100000
})
request_qingliu.interceptors.request.use(...interceptors_request)
request_qingliu.interceptors.response.use(...interceptors_response)
// export const request_server = axios.create({
//     baseURL: 'http://116.62.122.225:8084',
//     timeout: 100000
// })
// request_server.interceptors.request.use(...interceptors_request)
// request_server.interceptors.response.use(...interceptors_response)


request_localhost.interceptors.request.use(...interceptors_request)
request_localhost.interceptors.response.use(...interceptors_response)

export default request