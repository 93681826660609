import axios from "axios"

const key = "8b6fc51c10974b719fb4e032511fe102";
const appKey = "30c6030f6e144f08b51f7781699276c3"
const tdtKey = '094247775c509d9f6f2856f8fd660b33';
const gdKey = "e7d2cfb2ea3f8dc3be4642e816cc56ed";

let isSwtxDev = (process.env.VUE_APP_SWTX_ENV === "development")

const {
  VUE_APP_GAODE_URL,VUE_APP_TDT_URL
} = process.env
const juheApiUrl = process.env.VUE_APP_JUHE_API_TQ_URL;
const juheApiAK = process.env.VUE_APP_JUHE_API_QT_KEY;
// isSwtxDev = false;
export default class Map {

  static Regeo (params) {

    return new Promise((resolve, reject) => {
      if (isSwtxDev) {
        // 开发者模式
        axios.get("https://minedata.cn/service/lbs/reverse/v1/regeo", {
          params: {
            key,
            ...params
          }
        }).then(res => {
          if (res.data && res.data.regeocodes && res.data.regeocodes.length) {
            resolve(res.data.regeocodes[0].formatted_address)
          } else {
            reject(null)
          }
        }).catch(e => reject(e))
      } else {
        // 生产模式
        axios.get("http://32.128.12.138:21009/service/lbs/coder/reverse-geocoding", {
          params: {
            appKey,
            ...params
          }
        }).then(res => {
          if (res.data && res.data.data && res.data.data.poi) {
            resolve(res.data.data.poi)
          } else {
            reject(null)
          }
        }).catch(e => reject(e))
      }
    })
  }

  static AiRegeo (params) {

    return new Promise((resolve, reject) => {
      if (isSwtxDev) {
        // 开发者模式
        axios.get("https://minedata.cn/service/lbs/reverse/v1/regeo", {
          params: {
            key,
            ...params
          }
        }).then(res => {
          if (res.data && res.data.regeocodes && res.data.regeocodes.length) {
            resolve(res.data.regeocodes[0].formatted_address)
          } else {
            reject(null)
          }
        }).catch(e => reject(e))
      } else {
        // 生产模式
        axios.get("http://32.128.12.138:21009/service/lbs/coder/reverse-geocoding", {
          params: {
            appKey,
            ...params
          }
        }).then(res => {
          if (res.data && res.data.data && res.data.data.poi) {
            resolve(res.data.data)
          } else {
            reject(null)
          }
        }).catch(e => reject(e))
      }
    })
  }

  static Geo (params) {
    /* let promise = isSwtxDev
      ? axios.get("https://service.minedata.cn/service/lbs/search/v1/geo", {
          params: {
            key,
            address: params.keywords
          },
        }).then(res => {
          res.data.data = {
            rows: []
          };
          if(res.data.count > 0){
            res.data.data.rows = res.data.geocodes;
            res.data.data.rows.forEach((item) => {
              item.address = item.formatted_address;
              item.name = "";
            });
          }else{
            res.data.data.rows = [];
          }
          
          return res;
        })
      : axios.get(
          "http://32.128.12.138:21009/service/lbs/coder/geocoding2",
          {
            params: {
              appKey,
              city: "全国",
              page_size: 20,
              ...params,
            },
          }
        ); */

    let promise = axios.get("https://service.minedata.cn/service/lbs/search/v1/geo", {
      params: {
        key,
        address: params.keywords,
      },
    })
      .then((res) => {
        res.data.data = {
          rows: [],
        };
        if (res.data.count > 0) {
          res.data.data.rows = res.data.geocodes;
          res.data.data.rows.forEach((item) => {
            item.address = item.formatted_address;
            item.name = "";
          });
        } else {
          res.data.data.rows = [];
        }

        return res;
      });
    return promise
  }

  static Driv (params) {
    let promise = axios.get("http://32.128.12.138:21009/service/lbs/coder/geocoding2", {
      params: {
        appKey,
        city: "全国",
        page_size: 20,
        ...params
      }
    })
    return promise
  }
  /**
   *@Description: minedata接口搜索芜湖市内的数据
   *@Date: 2023-11-02 14:24:58
   *@Params1: 
   *@Return1: 
  */
  static KeyWords (name) {
    let promise = axios.get("https://service.minedata.cn/service/lbs/search/v1/keywords", {
      params: {
        key: '28ea4e2691984871bada8d9395557556',
        city: "芜湖",
        page_size: 20,
        keywords: name,
      }
    })
    return promise
  }
  /**
   *@Description: 高德兴趣点查询接口
   *@Author: name 
   *@Date: 2023-12-06 09:40:06
   *@Params1: 
   *@Return1: 
  */
  static KeyWordsGaoDe (name) {
    let keywords = name.keywords;
    let region = name.region ||'';
    // # VUE_APP_GAODE_URL='https://restapi.amap.com/v5/place/text?key=e7d2cfb2ea3f8dc3be4642e816cc56ed&region=芜湖市'
    let promise = axios.get(`${VUE_APP_GAODE_URL}?key=${gdKey}&keywords=${keywords}&region=${region}`);
    return promise;
    }
    /**
   *@Description: 天地图地名查询接口
   *@Author: name 
   *@Date: 2024年3月6日 09:17:01
   *@Params1: 
   *@Return1: 
  */
  static KeyWordsTDT (name) {
      let keywords = name.keywords;
      let url = `${VUE_APP_TDT_URL}?postStr={'keyWord':'${keywords}',
       'level':12, 'mapBound':'73,3,135,54', 'queryType':7,'start':0, 'count':10}&type=query&tk=${tdtKey}`;
      console.log(url, 'url');
    let promise = axios.get(url);
    return promise;
  }
  /**@Description :  ******************************************* 高德api接口，根据IP地址获取所在城市
   **@Date: 2024-09-13 11:43:24
  */
  static getCityNameByIP() {
    let url3 = `https://restapi.amap.com/v3/ip?key=${gdKey}`;
    let promise = axios.get(url3, {});
    return promise
  }
  /**@Description :  ******************************************* 聚合API接口，根据城市名称获取天气信息
   **@Date: 2024-09-13 15:32:09
  */
  static getWeatherByCityAdCode(cityAdCode) { 
    let url3 = `https://restapi.amap.com/v3/weather/weatherInfo?key=${gdKey}&city=${cityAdCode}&extensions=all`;
    let promise = axios.get(url3, {});
    return promise
  }
}