import request from '../request'

export default class Task {
  /**
   * 总览页航线任务
   */


  static ListAll(params) {
    return request.get(`/hawksystemserver/home/getTaskAndFlightLineList`, {
      params
    })
  }
  /**
   * 任务列表
   * @param {*} data
   * @returns
   */
  static List(data) {
    let {
      cate = 1, ...rest
    } = data

    //警情任务，常态巡查任务和临时紧急任务

    // hawksystemserver/task/organTaskList

    let cate_map = {
      1: '/hawksystemserver/web-task/normalInspectionTask', // 常态任务
      2: '/hawksystemserver/home/taskTotal', //警情任务
      3: '/hawksystemserver/task/getAllTaskList', //飞行任务
      4: '/hawksystemserver/jingqing/getAllJingQingList', //警情中心列表
    }

    return request.get(cate_map[cate], {
      params: rest,
    })
  }


  /**
   * 飞行日志列表
   * @param {*} params
   * @returns
   */
  static flightlog(params, pageNO, pageSize) {
    return request.get(`/hawksystemserver/flightLog/${pageNO}/${pageSize}`, {
      params
    })
  }

  static ListMessage(params) {
    return request.get('/hawksystemserver/task/getApproveTaskList', {
      params,
    })
  }
  // 删除任务
  static deleteTaskById(id) {
    return request.get(`/hawksystemserver/task/deleteTaskById/${id}`)
  }
  /**
   * 创建
   * @param {*} params
   * @returns
   */
  static Add(params) {
    return request.post('/hawksystemserver/task/insert-task', params)
  }

  /**
   * 审批
   * @param {*} params
   */
  static Edit(params) {
    return request.post(`/hawksystemserver/task/auditTask`, params)
  }
  /**
   * 警务情况汇总
   * @param {*} data
   * @returns
   */
  static StatStatus(data) {
    return request.get('/hawksystemserver/web-task/jingWuQingKuangHuiZong', {
      params: data,
    })
  }

  /**
   * 待办事项
   * @param {*} data
   * @returns
   */
  static StatTodo(data) {
    return request.get('/hawksystemserver/home/needToDoTotal', {
      params: data,
    })
  }

  /**
   * 数据监控
   * @param {*} data
   * @returns
   */
  //
  static StatMonitor(data) {
    return request.get('/hawksystemserver/home/dataTotal', {
      params: data,
    })
  }

  /**
   * 识别抓拍
   * @param {*} params
   * @returns
   */
  static StatSnap(params) {
    return request.get('/hawksystemserver/home/aiTotal', {
      params,
    })
  }

  /**
   * 警务处办情况
   * @param {*} params
   * @returns
   */
  // static StatHandle(params) {
  //   return request.get('/hawksystemserver/home/policeStatistics', {
  //     params,
  //   })
  // }
  /**
   * 任务执行情况
   * @param {*} params
   * @returns
   */
  static StatHandle(params) {
    return request.get('/hawksystemserver/home/policeStatistics2', {
      params,
    })
  }

  /**
   * 处警排名
   * @param {*} params
   * @returns
   */
  static StatHandleRank(params) {
    return request.get('/hawksystemserver/home/organTotal', {
      params,
    })
  }

  /**
   * 治安态势
   * @param {*} params
   */
  static StatSecuritySituation(params) {
    return request.get('/hawksystemserver/home/zhi_an_shi_tai', {
      params,
    })
  }

  /**
   * 任务办理方式
   * @param {*} params
   * @returns
   */
  static DrdHandleType(params) {
    return request.get('', {
      params,
    })
  }

  // /**
  //  * 任务类型
  //  * @param {*} params
  //  * @returns
  //  */
  // static DrdCate(params) {
  //     return request.get('', {
  //         params
  //     })
  // }

  /**
   * 任务类别
   * @param {*} params
   * @returns
   */
  static DrdType(params) {
    return request.get('/hawksystemserver/task/getTaskTypeList', {
      params,
    })
  }

  /**
   * 飞行任务详情
   * @param {*} params
   * @returns
   */
  static FlightDetail(id) {
    console.log('飞行任务详情', id);
    return request.get(`/hawksystemserver/task/getInfoById/${id}`)
  }

  /**
   * 飞行任务编辑
   * @param {*} params
   * @returns
   */
  static FlightEdit(data) {
    return request.post(`/hawksystemserver/task/modifyIndexTaskInfo`, data)
  }

  /**
   * 当前账号所属机构及其下级所有机构待审批列表
   * @param {*} params
   * @returns
   */
  static ListAudit(params) {
    return request.get(`/hawksystemserver/home/getDaiBanShenPiList`, params)
  }

  /**
   * 警情中心航线预览
   * @param {*} params
   * @returns
   */
  static JingQingTrack(params) {
    return request.get(`/hawksystemserver/jingqing/jingQingTrack`, {
      params
    })
  }
  /**
   * 飞行任务历史轨迹接口
   * @param {*} params
   * @returns
   */
  static airtaskTrack(taskId) {
    return request.get(`/hawksystemserver/historical/tracks/${taskId}`);
  }
  /**
   * 飞行任务视频回放接口
   * @param {*} params
   * @returns
   */
  static airtaskVideo(taskId, params) {
    return request.get(`/hawksystemserver/historical/videos/${taskId}`, {
      params
    });
  }
  /**
   * 飞行任务历史图片接口
   * @param {*} params
   * @returns
   */
  static airtaskPhoto(taskId) {
    return request.get(`/hawksystemserver/historical/images/${taskId}`);
  }
  /**
   * 挂载类型接口回放接口
   * @param {*} params
   * @returns 
   */

  /**
   * 常态任务所有历史轨迹
   * @param {*} params
   * @returns
   */
  static allNormalTrack(pageNum, pageSize) {
    return request.get(`/hawksystemserver/flightLog/getTotalHistoricalTrack/${pageNum}/${pageSize}`);
  }
  /**
   * 常态任务所有历史轨迹数量
   * @param {*} params
   * @returns
   */
  static allNormalTrackNum() {
    return request.get(`/hawksystemserver/flightLog/getTotalHistoricalTrackCount`);
  }

  /**
   * 任务数据统计表格接口
   * @param {*} params
   * @returns
   */
  static TaskChartInfo(params) {
    return request.get(`/hawksystemserver/home/policeStatistics2`, {
      params
    })
  }
  /**
   * 鹰视起飞任务监听
   * @param {*} params
   * @returns
   */
  static flytaskLisener(params) {
    return request.get(`/hawksystemserver/task2uav/oneClickTakeoffTaskMonitoring`, {
      params
    })
  }

  /**
   * 鹰视改变任务状态
   * @param {*} params
   */
  static changeStatus(params) {
    return request.post(`/hawksystemserver/ground-station/task-update`, params)
  }
  //视图列表
  static photoAndvideo(visitQueryForm) {
    return request.post("/upload/visit/page",
      visitQueryForm,
    );
  }
}