import { request_nest } from "../request";
import request from "../request";

export default class Uav {
  /**@Description :  ******************************************* 无人机起降场下拉列表
   **@Date: 2024-07-03 16:38:32
  */
  static uavLandingFieldList () {
    return request.get("/hawksystemserver/task/group/uavLandingFieldList");
  }
  /**@Description :  ******************************************* 无人机型号下拉列表
   **@Date: 2024-07-03 16:51:17
  */
  static uavModelList () {
    return request.get("/hawksystemserver/task/group/uavModelList");
  }
}
