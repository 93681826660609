/**@Description :  ******************************************* 芜湖消防
 **@Date: 2024-05-13 10:48:53
*/
import request from '../request'


export default class WHXF {
  /**
   * 列表
   * @param {*} params
   * @returns
   */
  static List(data) {
    return request.post('/hawksystemserver/task/group/list', data);
  }
  /**@Description :  ******************************************* 12.1 主任务创建
   **@Date: 2024-05-13 18:20:17
  */
  static addNewTask (data) {
    return request.post('/hawksystemserver/task/group/create', data);
  }
  /**@Description :  ******************************************* 主任务修改 
   **@Date: 2024-05-14 16:53:09 
  */
    static editNewTask (data) {
    return request.post('/hawksystemserver/task/group/update', data);
  }
  /**@Description :  ******************************************* 获取子任务列表
   **@Date: 2024-05-13 20:25:37
  */
  static getSubTaskList(params) {
    return request.get(`/hawksystemserver/task/group/detail?id=${params}`);
  }
  /**@Description :  ******************************************* 主任务状态统计
   **@Date: 2024-05-15 09:08:10
  */
  static staticMainTaskStatus() {
    return request.get(`/hawksystemserver/task/group/statusCount`);
  }
  /**@Description :  ******************************************* 12.6 子任务操作记录查询
   **@Date: 2024-05-15 14:55:02
  */
  static getSubTaskLog(subId) {
    return request.get(`/hawksystemserver/task/log?taskId=${subId}`);
  }
  /**@Description :  ******************************************* 12.5 主任务更新状态（执行、取消、完成操作）
   * 新状态：执行3，取消99，完成1。
   **@Date: 2024-05-16 17:07:08
  */
  static mainTaskStatusUpdate(data) {
    // return request.post(`/hawksystemserver/task/group/updstatus`, data);
    return request.post(`/hawksystemserver/task/group/updStatus`, data);
  }
  /**@Description :  ******************************************* 12.6 子任务更新状态（执行、取消、完成操作）
   * 新状态：执行3，取消99，完成1。
   **@Date: 2024-05-16 17:07:08
  */
  static subTaskStatusUpdate(data) {
    return request.post(`/hawksystemserver/task/group/updSubTaskStatus`, data);
  }
  /**@Description :  ******************************************* 12.9 无人机列表查询
   * 类型id：4：Q20；18：TD550
   **@Date: 2024-05-17 13:37:57
  */
  static getDeviceListForLogistics(goodsId) {
    return request.get(`/hawksystemserver/device/getDeviceListForLogistics?goodsId=${goodsId}`);
  }
  /**@Description :  ******************************************* 12.11 消息列表
   **@Date: 2024-05-18 11:18:21
  */
  static getJQTaskNoticeList(data) {
    return request.post(`/hawksystemserver/task/notice/list`, data);
  }
  /**@Description :  ******************************************* 消息创建
   **@Date: 2024-05-21 20:31:39 
  */
    static getJQCreateNoticeAlert(data) {
    return request.post(`/hawksystemserver/task/notice/create`, data);
  }
  /**@Description :  ******************************************* 12.12 消息详情 警情
   **@Date: 2024-06-04 18:25:24
  */
  static getJQNoticeDetail(id) {
    console.log('id', id);
    
    // let aid = '1797935257857556480'
    // return request.get(`/hawksystemserver/task/notice/detail?id=${aid}`);
  }
  /**@Description :  ******************************************* 12.4 主任务删除
   **@Date: 2024-07-16 09:56:22
  */
  static deleteMainTask(ids) {
    return request.post(`/hawksystemserver/task/group/del/${ids}`);
  }
}
